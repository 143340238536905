<template>
  <div>
    <received-recordings></received-recordings>
  </div>
</template>

<script>
import ReceivedRecordings from "../../components/ReceivedRecordings";
export default {
  components: {
    ReceivedRecordings,
  },
  name: "ReceivedPage",
};
</script>

<style scoped></style>
